import React, { useEffect, useRef, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";
import { dailySignInRewardSlice } from "../../helper/customSlice";
import { dispatchStore } from "../../lib/dispatchStore";
import LoadingComponent from "../../components/loading/LoadingComponent";
import { BeatLoader, ClipLoader } from "react-spinners";
import PaymentMethodModal from "../LuckyDraw/PaymentMethodModal";
import AlertPopup from "../../components/AlertPopUp/AlertPopUp";

const getFirstDayOfMonth = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  return `${year}-${month}-01`;
};

const FailDailyRewardSigninModal = () => {
  const fileInputRef = useRef(null);

  // Change language
  const changeLanguage =
  window.localStorage.currentLanguage ? window.localStorage.currentLanguage : "en";
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);

  const rewardList = useSelector((state) => state?.reward?.list?.data?.data);
  const FailDailyRewardSigninModal = useSelector(
    (state) => state?.dailySignInRewardSlice?.FailDailyRewardSigninModal
  );
  const PaymentIsDone = useSelector(
    (state) => state?.dailySignInRewardSlice?.PaymentIsDone
  );
  const recoverAmount = useSelector(
    (state) => state?.others?.setting?.data?.data?.recover_amount
  );

  const [serverError, setServerError] = useState(null);
  
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
  const [useCardNextTime, setUseCardNextTime] = useState(false);
  const [paymentImage, setPaymentImage] = useState(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      // Store the selected file in a ref
      setServerError("Please select Payment Image");
      return;
    }
    setPaymentImage(file);
  };

  function restartFunc(params) {
    const firstDay = getFirstDayOfMonth();
    callApi("reward/restart")
      .withBody({ date: firstDay })
      .loadingGroup("reward/restart")
      .executeDispatch()
      .then((res) => {
        if (res.message == "Success") {
          callApi("reward/list").loadingGroup("reward/list").executeDispatch();
          dispatchStore(
            dailySignInRewardSlice.actions.setFailDailyRewardSigninModal(false)
          );
        }
      });
  }
  function recoverFunc(params) {
    const formData = new FormData();
    formData.append("payment_id", selectedPaymentOption);
    formData.append("payment_screenshot", paymentImage);

    callApi("reward/recover")
      .withBody(formData)
      .loadingGroup("reward/recover")
      .executeDispatch()
      .then((res) => {
        if (res.message == "Success") {
          callApi("reward/list").loadingGroup("reward/list").executeDispatch();
          setShowPaymentMethodModal(false);
          dispatchStore(dailySignInRewardSlice.actions.setPaymentIsDone(true));
          dispatchStore(
            dailySignInRewardSlice.actions.setFailDailyRewardSigninModal(false)
          );
          dispatchStore(
            dailySignInRewardSlice.actions.setPaymentSuccessModal(true)
          );
        }
        else{
          setServerError(res?.message || "Unknown Server Error");
        }
      });
  }

  if (!FailDailyRewardSigninModal) return null;

  return (
    <>
      {serverError && (
        <AlertPopup
          type={"error"}
          btnLabel={getLanguageFile.propbox_ok}
          text={serverError}
          onClick={() => setServerError(null)}
        />
      )}
      <div className="fixed inset-0 z-3 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white w-[350px] p-6 rounded-lg relative">
          <button
            onClick={() =>
              dispatchStore(
                dailySignInRewardSlice.actions.setFailDailyRewardSigninModal(
                  false
                )
              )
            }
            className="absolute top-2 right-2"
          >
            <XMarkIcon className="w-5 h-5 text-gray-500" />
          </button>

          <h3 className="fs-14 font-semibold mb-2 text-center"></h3>

          <div className="border-[1px] border-black py-5 mb-4">
            <h2 className="fs-14 font-semibold mb-2 text-center">
            {rewardList?.filter((each)=> each?.status == false).length == 1 ?
              "You failed to sign in for 1 day."
              :
              rewardList?.filter((each)=> each?.status == false).length > 1 ?
              getLanguageFile.fail_to_sign_in_con_1 + rewardList?.filter((each)=> each?.status == false).length + getLanguageFile.fail_to_sign_in_con_2
              :
              "You failed to sign in"

            }
            </h2>
            <h2 className="fs-14 font-semibold mb-2 text-center">
              {getLanguageFile.you_can_recover_1}{recoverAmount}Ks{getLanguageFile.you_can_recover_2}
            </h2>
          </div>

          <div className="flex gap-2">
            <LoadingComponent
              loadingGroup={"reward/restart"}
              loadingDesign={
                <button
                  onClick={() => restartFunc()}
                  className="bg-red-500 border-[1px] fs-12 border-foundation-grey-normal-hover text-white font-semibold rounded-full px-4 py-2 w-full"
                >
                  <ClipLoader color="#ffffff" size={13} />
                </button>
              }
            >
              <button
                onClick={() => restartFunc()}
                className="bg-red-500 border-[1px] fs-12 border-foundation-grey-normal-hover text-white font-semibold rounded-full px-4 py-2 w-full"
              >
                {getLanguageFile.restart_from}
              </button>
            </LoadingComponent>

            <LoadingComponent
              loadingGroup={"reward/recover"}
              loadingDesign={
                <button
                  onClick={() => restartFunc()}
                  className="bg-gold-500 border-[1px] fs-12 border-foundation-grey-normal-hover text-500 font-semibold rounded-full px-4 py-2 w-full"
                >
                  <ClipLoader color="#ffffff" size={13} />
                </button>
              }
            >
              <button
                onClick={() => setShowPaymentMethodModal(true)}
                className={`${PaymentIsDone ? `bg-gold-500` : `bg-gold-500`}  border-[1px] fs-12 border-foundation-grey-normal-hover text-500 font-semibold rounded-full px-4 py-2 w-full`}
              >
                {getLanguageFile.recover}
              </button>
            </LoadingComponent>
          </div>
        </div>

        <PaymentMethodModal
          show={showPaymentMethodModal}
          onClose={() => setShowPaymentMethodModal(false)}
          handleEdit={() => setShowPaymentMethodModal(false)}
          selectedPaymentOption={selectedPaymentOption}
          setSelectedPaymentOption={setSelectedPaymentOption}
          useCardNextTime={useCardNextTime}
          setUseCardNextTime={setUseCardNextTime}
          handleBuy={recoverFunc}
          handleFileChange={handleFileChange}
          fileInputRef={fileInputRef}
          paymentImage={paymentImage}
          loadingGroup={"reward/recover"}
        />
      </div>
    </>
  );
};

export default FailDailyRewardSigninModal;
