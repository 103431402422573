import React, { useEffect, useState } from "react";
import { InformationCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useSelector } from "react-redux";
import callApi from "../../services/api/apiClient";
import { ClipLoader } from "react-spinners";
import LoadingComponent from "../../components/loading/LoadingComponent";

const PaymentMethodModal = ({
  show,
  onClose,
  selectedPaymentOption,
  setSelectedPaymentOption,
  useCardNextTime,
  setUseCardNextTime,
  handleBuy,
  handleEdit,
  paymentImage,
  handleFileChange,
  fileInputRef,
  loadingGroup,
}) => {
  const [changeLanguage, setChangeLanguage] = useState(
    window.localStorage.currentLanguage || "en"
  );

  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);
  const paymentlist = useSelector(
    (state) => state?.cart?.paymentlist?.data?.data
  );
  const paymentdetail = useSelector(
    (state) => state?.cart?.paymentdetail?.data?.data
  );

  const toggleUseCardNextTime = () => {
    setUseCardNextTime(!useCardNextTime);
  };

  useEffect(() => {
    callApi("cart/paymentlist").executeDispatch();
  }, []);

  useEffect(() => {
    if (selectedPaymentOption) {
      callApi("cart/paymentdetail")
        .withKeyParameter({ paymentID: selectedPaymentOption })
        .executeDispatch();
    }
  }, [selectedPaymentOption]);

  if (!show) return null;
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white w-[300px] p-6 rounded-lg relative">
        <button onClick={onClose} className="absolute top-2 right-2">
          <XMarkIcon className="w-5 h-5 text-gray-500" />
        </button>
        <div className="flex justify-center mb-4">
          <InformationCircleIcon className="w-10 h-10 text-green-500" />
        </div>
        <h2 className="fs-14 font-bold mb-4 text-center">Payment Method</h2>
        <div className="border border-gray-300 rounded-lg p-4 mb-3">
          <div className="mb-0">
            <div className="flex items-center space-x-4 mb-0">
              {paymentlist?.map((item, key) => (
                <label key={key} className="inline-flex items-center">
                  <input
                    type="radio"
                    name="paymentMethod"
                    className="form-radio"
                    value={item?.id}
                    checked={selectedPaymentOption == item?.id}
                    onChange={(e) => setSelectedPaymentOption(e.target.value)}
                  />
                  {item?.logo ? (
                    <img
                      className="w-[auto] h-[20px] ms-1"
                      src={item?.logo}
                      alt={item?.name}
                    />
                  ) : (
                    <span className="ml-2 text-500 fs-12">{item?.name}</span>
                  )}
                </label>
              ))}
            </div>
            <div className="grid grid-cols-1 gap-4">
              {paymentdetail?.payment_numbers?.[0]?.account_name && (
                <div className="text-500 fs-12 mt-4">
                  Merchent name:{" "}
                  {paymentdetail?.payment_numbers?.[0]?.account_name}
                </div>
              )}
              {paymentdetail?.payment_numbers?.[0]?.account_number && (
                <div className="text-500 fs-12">
                  Merchent account no:{" "}
                  {paymentdetail?.payment_numbers?.[0]?.account_number}
                </div>
              )}
              {paymentdetail?.payment_numbers?.[0]?.account_number && (
                <div className="flex">
                  <div className="text-500 fs-12">Payment ScreenShot: </div>
                  <label className="text-black fs-12 cursor-pointer bg-gold-500 py-3 px-4 rounded-lg ms-2">
                    {paymentImage ? "Reselect" : "Browse"}

                    <input
                      type="file"
                      accept="image/*"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      className="hidden"
                      // style={{ display: "none" }}
                    />
                  </label>
                </div>
              )}
              {paymentImage && (
                <img
                  src={URL.createObjectURL(paymentImage)}
                  alt="Selected"
                  className="max-w-full max-h-80 rounded-lg border-2 border-gray-300 p-2"
                />
              )}
              {/* <div>
                  <label className="text-500 fs-12">
                    {getLanguageFile.card_number}
                  </label>
                  <input
                    type="text"
                    className="form-input mt-1 block w-full fs-12 p-2 border border-gray-300 rounded"
                    placeholder="number"
                  />
                </div>
                <div>
                  <label className="text-500 fs-12">
                    {getLanguageFile.name_on_card}
                  </label>
                  <input
                    type="text"
                    className="form-input mt-1 block w-full fs-12 p-2 border border-gray-300 rounded"
                    placeholder="name"
                  />
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="text-500 fs-12">
                      {getLanguageFile.expire_date}
                    </label>
                    <input
                      type="text"
                      className="form-input mt-1 block w-full fs-12 p-2 border border-gray-300 rounded"
                      placeholder="MM / YY"
                    />
                  </div>
                  <div>
                    <label className="text-500 fs-12">
                      {getLanguageFile.cvc}
                    </label>
                    <input
                      type="text"
                      className="form-input mt-1 block w-full fs-12 p-2 border border-gray-300 rounded"
                      placeholder="123"
                    />
                  </div>
                </div> */}
            </div>
            {/* <div className="mt-4 flex items-center">
                <button
                  type="button"
                  onClick={toggleUseCardNextTime}
                  className="flex items-center"
                >
                  <div
                    className={`relative inline-block w-12 h-6 align-middle select-none transition duration-200 ease-in-out ${
                      useCardNextTime ? "bg-green-500" : "bg-gray-300"
                    } border border-gray-300 rounded-full`}
                  >
                    <input
                      type="checkbox"
                      name="useCardNextTime"
                      id="useCardNextTime"
                      className="absolute opacity-0 w-0 h-0"
                      checked={useCardNextTime}
                      onChange={toggleUseCardNextTime}
                    />
                    <span
                      className={`absolute left-0 inline-block w-6 h-6 transform bg-white border rounded-full transition-transform duration-200 ease-in-out ${
                        useCardNextTime ? "translate-x-6" : "translate-x-0"
                      }`}
                    ></span>
                  </div>
                  <span className="ml-2 text-500 fs-12 font-medium">
                    {getLanguageFile.use_card_next_time}
                  </span>
                </button>
              </div> */}
          </div>
        </div>
        <div className="flex justify-between space-x-2 fs-14">
          <button
            onClick={handleEdit}
            className="bg-white border-[1px] border-foundation-grey-normal-hover text-500 font-semibold rounded-full px-4 py-2 flex-1"
          >
            Go Back
          </button>

          <LoadingComponent
            loadingGroup={loadingGroup}
            loadingDesign={
              <button
                disabled
                className="bg-yellow-400 text-500 font-semibold rounded-full px-4 py-2 flex-1 cursor-progress"
              >
                <ClipLoader className="text-500" size={13} />
              </button>
            }
          >
            <button
              onClick={() => {
                handleBuy();
              }}
              className="bg-yellow-400 text-500 font-semibold rounded-full px-4 py-2 flex-1"
            >
              Confirm
            </button>
          </LoadingComponent>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethodModal;
