import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";
import { dailySignInRewardSlice } from "../../helper/customSlice";
import { dispatchStore } from "../../lib/dispatchStore";
import LoadingComponent from "../../components/loading/LoadingComponent";
import { ClipLoader } from "react-spinners";

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const DailyRewardModal = () => {
  // Change language
  const changeLanguage =
    window.localStorage.currentLanguage ? window.localStorage.currentLanguage : "en";
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);
  const rewardList = useSelector((state) => state?.reward?.list?.data?.data);
  const showDailyRewardModal = useSelector(
    (state) => state?.dailySignInRewardSlice?.showDailyRewardModal
  );
  const PaymentIsDone = useSelector(
    (state) => state?.dailySignInRewardSlice?.PaymentIsDone
  );
  const daily_reward_point = useSelector(
    (state) => state?.others?.setting?.data?.data?.daily_reward_point
  );

  const [animate, setAnimate] = useState(null);

  useEffect(() => {
    callApi("reward/list").loadingGroup("reward/list").executeDispatch();
    // callApi("reward/claimlist").executeDispatch();
    // callApi("reward/claim").withBody({date: "2024-11-30"}).executeDispatch();
    // callApi("reward/restart").withBody({date: "2024-11-30"}).executeDispatch();
  }, []);

  useEffect(() => {
    if (showDailyRewardModal) {
      callApi("reward/list").loadingGroup("reward/list").executeDispatch();
      if (rewardList?.some((obj) => obj.status == false)) {
        if (PaymentIsDone) {
          dispatchStore(
            dailySignInRewardSlice.actions.setPaymentSuccessModal(true)
          );
        } else {
          setTimeout(() => {
            dispatchStore(
              dailySignInRewardSlice.actions.setFailDailyRewardSigninModal(true)
            );
          }, 1500);
        }
      } else {
        dispatchStore(dailySignInRewardSlice.actions.setPaymentIsDone(false));
      }
    } else {
      setAnimate(false);
    }
  }, [showDailyRewardModal]);

  useEffect(() => {
    if (rewardList) {
      // const formattedDate = "2024-12-04";
      const formattedDate = formatDate(new Date());
      // console.log(formattedDate, "formattedDate");
      const containsDate = rewardList?.some(
        (obj) => obj.date === formattedDate
      );
      if (!containsDate) {
        setAnimate(true);
        dispatchStore(
          dailySignInRewardSlice.actions.setShowDailyRewardModal(true)
        );
        callApi("reward/create")
          .withBody({ date: formattedDate })
          .executeDispatch()
          .then((res) => {
            // console.log(res, "create reward");
            if (res?.message == "Success") {
              callApi("reward/list")
                .loadingGroup("reward/list")
                .executeDispatch();
              setTimeout(() => {
                dispatchStore(
                  dailySignInRewardSlice.actions.setSuccessfulDailyRewardSigninModal(
                    true
                  )
                );
              }, 3000);
            }
          });
      }
    }
  }, [rewardList]);

  if (!showDailyRewardModal) return null;
  return (
    <div className="fixed inset-0 z-2 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white w-[350px] p-6 rounded-lg relative">
        <button
          onClick={() =>
            dispatchStore(
              dailySignInRewardSlice.actions.setShowDailyRewardModal(false)
            )
          }
          className="absolute top-2 right-2"
        >
          <XMarkIcon className="w-5 h-5 text-gray-500" />
        </button>
        <div className="flex flex-col items-center mb-4">
          <h2 className="fs-20 font-semibold mb-2 text-center">
            {getLanguageFile.daily_sign_title}
          </h2>
          <h3
            className="fs-24 font-bold mb-2 text-center"
            style={{ lineHeight: "24px" }}
          ></h3>

          <h3 className="fs-13 font-semibold mb-5 text-center">
            {getLanguageFile.sign_in_for_1}{daily_reward_point}
            {getLanguageFile.sign_in_for_2}
          </h3>
          <div className="grid grid-cols-7 gap-2 mb-6">
            {Array.from({ length: 30 }, (_, i) => i + 1).map((day, index) => (
              <LoadingComponent
                key={index}
                loadingGroup={"reward/list"}
                loadingDesign={
                  <div
                    key={index}
                    className={`w-10 h-10 flex items-center justify-center rounded-md border-[2px] cursor-default select-none text-shine border-shine 
                    ${"bg-shine bg-[length:200%_100%] animate-shine"}
                    `}
                  ></div>
                }
              >
                <div
                  key={index}
                  className={`w-10 h-10 flex items-center justify-center rounded-md border-[2px] cursor-default select-none text-500 border-gold-900 
                  ${
                    rewardList?.[index]?.status == true
                      ? "bg-green-400"
                      : rewardList?.[index]?.status == false
                      ? "bg-red-400"
                      : "bg-white"
                  }
                    ${
                      rewardList?.[index]?.date == formatDate(new Date()) &&
                      animate == true &&
                      "animate-pulse"
                    }
                    `}
                >
                  {day}
                </div>
              </LoadingComponent>
            ))}
          </div>
        </div>

        <button
          onClick={() =>
            dispatchStore(
              dailySignInRewardSlice.actions.setShowDailyRewardModal(false)
            )
          }
          className="bg-white border-[1px] fs-14 border-foundation-grey-normal-hover text-500 font-semibold rounded-full px-4 py-2 w-full"
        >
          {getLanguageFile.close}
        </button>
      </div>
    </div>
  );
};

export default DailyRewardModal;
