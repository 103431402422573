import React, { useState, useEffect } from "react";
import Avatar from "../../assets/icons/Avatar.png";
import { StarIcon } from "@heroicons/react/24/solid";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";
import BottomNav from "../../layout/BottomNav";
import ReviewModal from "../product/ReviewModal";
import AlertPopup from "../../components/AlertPopUp/AlertPopUp";
import LoadingComponent from "../../components/loading/LoadingComponent";
import { ClipLoader } from "react-spinners";
import AuthHeader from "../../layout/AuthHeader";

export default function PublicReview() {
  const [reviewModalToggle, setReviewModalToggle] = useState(false);
  const [reviewAlertmessage, setReviewAlertmessage] = useState("");
  const [serverError, setServerError] = useState(null);

  const allReview = useSelector((state) => state?.review?.getAll?.data?.data);
  const [changeLanguage, setChangeLanguage] = useState(
    window.localStorage.currentLanguage || "en"
  );
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);

  useEffect(() => {
    callApi("review/getAll").loadingGroup("PublicReview").executeDispatch();
  }, []);

  return (
    <>
      {reviewAlertmessage && (
        <AlertPopup
          type={"success"}
          btnLabel={"Ok"}
          text={reviewAlertmessage}
          onClick={() => {
            setReviewAlertmessage(null);
          }}
        />
      )}
      {serverError && (
        <AlertPopup
          type={"error"}
          btnLabel={"Ok"}
          text={serverError}
          onClick={() => setServerError(null)}
        />
      )}

      <AuthHeader title={"PublicReview"} backkey={true} />

      <div
        id="body"
        className="auth bottombar"
        style={{ overflow: "hidden auto", padding: "16px" }}
      >
        <LoadingComponent
          loadingGroup={"PublicReview"}
          loadingDesign={
            <div className="flex justify-center content-center w-100 mt-5">
              <ClipLoader color="#fed801" size={20} />
            </div>
          }
        >
          <section className="container p-lr-16 m-b-8">
            <button
              onClick={() => setReviewModalToggle(true)}
              className="flex items-center justify-center m-tb-16 bg-white text-500 font-semibold border-[1px] border-500 rounded-full p-lr-16 p-tb-6 fs-11"
            >
              Post Review
            </button>

            <div className="fs-12 font-medium m-b-8">
              {"Reviews (" + allReview?.length + ")"}
            </div>

            {allReview?.map((eachReview) => (
              <div className="bg-foundation-grey-light-hover p-all-8 m-b-8 relative">
                <div className="flex justify-between items-center">
                  <div className="flex items-center mb-1">
                    <img
                      src={eachReview?.user_image || Avatar}
                      alt="Slide 1"
                      className="flex w-[24px] items-center gap-[4px] rounded-full me-1"
                    />
                    <span className="fs-12 font-medium">
                      {eachReview?.user}
                    </span>
                  </div>

                  {/* <span className="fs-12 text-300">30 Jan 2024</span> */}
                </div>

                <div className="flex gap-[2px] mb-1">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <StarIcon
                      className={`flex w-[16px] h-[16px] justify-center items-center shrink-0 ${
                        star <= eachReview?.rating
                          ? "text-yellow-500"
                          : "text-gray-400"
                      }`}
                    />
                  ))}
                </div>

                <p className="fs-12 text-400">{eachReview?.comment}</p>

                <div className={eachReview?.files?.length == 1 ? "flex flex-col" : eachReview?.files?.length%3 == 0 ? "grid grid-cols-3" : "grid grid-cols-2"}>
                  {eachReview?.files?.map((eachRevImg, key) => {
                    return (
                      <img
                        key={key}
                        src={eachRevImg}
                        alt="eachRevImg"
                        className="w-100"
                        // className={eachReview?.files?.length == 1 ? "w-100" : "w-50"}
                      />
                    );
                  })}
                </div>
              </div>
            ))}
          </section>
        </LoadingComponent>
      </div>

      <BottomNav getLanguageFile={getLanguageFile} />

      <ReviewModal
        visible={reviewModalToggle}
        onClose={() => setReviewModalToggle(false)}
        setAlertmessage={setReviewAlertmessage}
        setServerError={setServerError}
      />
    </>
  );
}
