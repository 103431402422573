import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {

}

export const notiSlice = createSlice({
  name: 'notiSlice',
  initialState: [],
  reducers: {
    setNotiSlice: (state, action) => {
      if (state.length === 0) {
        return action.payload;
      } else {
        // Otherwise, filter out existing notifications and merge
        const existingIds = new Set(state.map(notification => notification.id));
        const newNotifications = action.payload.filter(notification => !existingIds.has(notification.id));
        state.push(...newNotifications);
      }
    },
  },
});

export const liveFetchBoolean = createSlice({
  name: 'liveFetchBoolean',
  initialState: 0,
  reducers: {
    setLiveFetchBoolean: (state, action) => {
      return state === 0 ? 1 : 0;
    },
  },
});


export const notiCountSlice = createSlice({
  name: 'notiCountSlice',
  initialState: 0,
  reducers: {
    plusNotiCount: (state, action) => {
      return state+1;
    },
  },
});

export const dailySignInRewardSlice = createSlice({
  name: 'dailySignInRewardSlice',
  initialState: {
    showDailyRewardModal: false,
    SuccessfulDailyRewardSigninModal: false,
    FailDailyRewardSigninModal: false,
    PaymentSuccessModal: false,
    PaymentIsDone: false,
  },
  reducers: {
    setShowDailyRewardModal: (state, action) => {
      state.showDailyRewardModal = action?.payload
      return state;
    },
    setSuccessfulDailyRewardSigninModal: (state, action) => {
      state.SuccessfulDailyRewardSigninModal = action?.payload
      return state;
    },
    setFailDailyRewardSigninModal: (state, action) => {
      state.FailDailyRewardSigninModal = action?.payload
      return state;
    },
    setPaymentSuccessModal: (state, action) => {
      state.PaymentSuccessModal = action?.payload
      return state;
    },
    setPaymentIsDone: (state, action) => {
      state.PaymentIsDone = action?.payload
      return state;
    },
  },
});

export default {
  notiSlice,
  notiCountSlice,
  liveFetchBoolean,
  dailySignInRewardSlice,
}