import React, { useEffect, useRef, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";
import { dailySignInRewardSlice } from "../../helper/customSlice";
import { dispatchStore } from "../../lib/dispatchStore";

const PaymentSuccessModal = () => {
  // Change language
  const changeLanguage =
    window.localStorage.currentLanguage ? window.localStorage.currentLanguage : "en";
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);
  const PaymentSuccessModal = useSelector(
    (state) => state?.dailySignInRewardSlice?.PaymentSuccessModal
  );

  if (!PaymentSuccessModal) return null;
  return (
    <div className="fixed inset-0 z-3 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white w-[350px] p-6 rounded-lg relative">
        <button
          onClick={() =>
            dispatchStore(
              dailySignInRewardSlice.actions.setPaymentSuccessModal(false)
            )
          }
          className="absolute top-2 right-2"
        >
          <XMarkIcon className="w-5 h-5 text-gray-500" />
        </button>

        <div className="border-[1px] border-black py-5 mb-4">
          <h2 className="fs-15 font-semibold mb-2 text-center">
            Admin is processing Recovery!
          </h2>
          <h2 className="fs-15 font-semibold mb-2 text-center">
            Please wait for admin approval. This may take some time.
          </h2>
        </div>

        <button
          onClick={() =>
            dispatchStore(
              dailySignInRewardSlice.actions.setPaymentSuccessModal(false)
            )
          }
          className="bg-white border-[1px] fs-14 border-foundation-grey-normal-hover text-500 font-semibold rounded-full px-4 py-2 w-full"
        >
          Ok
        </button>
      </div>
    </div>
  );
};

export default PaymentSuccessModal;
