import React, { useState, useEffect, useRef, act } from "react";
import { Link, useFetcher, useLocation, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Header from "../../layout/Header";
import BottomNav from "../../layout/BottomNav";
import { StarIcon } from "@heroicons/react/24/solid";
import LuckydrawItem1 from "../../assets/images/LuckydrawItem1.jpg";
import { useSelector } from "react-redux";
import BuyTicketModal from "./BuyTicketModal";
import ConfirmTicketModal from "./ConfirmTicketModal";
import callApi from "../../services/api/apiClient";
import AlertPopup from "../../components/AlertPopUp/AlertPopUp";
import PaymentMethodModal from "./PaymentMethodModal";
import { slice } from "../../redux/reducers/reducer";
import { dispatchStore } from "../../lib/dispatchStore";
import { useCallback } from "react";

export default function LuckyDraw() {
  let navigate = useNavigate();
  const location = useLocation();
  const { data, buytokenid } = location.state || {};
  const fileInputRef = useRef(null);

  const [changeLanguage, setChangeLanguage] = useState(
    localStorage.getItem("currentLanguage") || "en"
  );
  const [activeTab, setActiveTab] = useState(data || "history");
  const [showBuyTicketModal, setShowBuyTicketModal] = useState(false);
  const [showConfirmTicketModal, setShowConfirmTicketModal] = useState(false);
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const changeLanguageData = (languageType) => {
    setChangeLanguage(languageType);
  };
  const [page, setPage] = useState(1);
  const ticketProduct = useSelector(
    (state) => state?.products?.ticket?.data?.data
  );
  const myPrizesProduct = useSelector(
    (state) => state?.luckydraw?.myPrices?.data?.data
  );

  const myClainProduct = useSelector(
    (state) => state?.luckydraw?.claimPrize?.data?.data
  );
  const historyticketProduct = useSelector(
    (state) => state?.history?.luckydraw?.data?.data
  );
  const LuckyDrawLiveProduct = useSelector(
    (state) => state?.luckydraw?.liveList?.data?.data
  );

  const AccessToken = useSelector((state) => state.AccessToken);

  const [serverError, setServerError] = useState(null);
  const [tokenNotEnoughError, setTokenNotEnoughError] = useState(null);
  const [claimSuccess, setClaimSuccess] = useState(null);

  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);

  const [buyAmount, setBuyAmount] = useState(1);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
  const [useCardNextTime, setUseCardNextTime] = useState(false);
  const [paymentImage, setPaymentImage] = useState(null);

  async function handleBuy(product) {
    // if (selectedPaymentOption) {
    //   if (paymentImage) {
    //     const formData = new FormData();
    //     formData.append("product_id", selectedProduct?.id);
    //     formData.append("payment_id", selectedPaymentOption);
    //     formData.append("ticket_count", buyAmount);
    //     formData.append("payment_screenshot", paymentImage);
    //     callApi("cart/buyticket")
    //       .withBody(formData)
    //       .executeDispatch()
    //       .then((res) => {
    //         if (res?.status_code == 200) {
    //           navigate("/ticket-buy-success", { state: { data: res } });
    //         } else {
    //           setServerError(res?.message || "Unknown Server Error");
    //         }
    //       });
    //   }
    //   else{
    //     setServerError("Please select Payment Image");
    //   }
    // } else {
    //   setServerError("Please select Payment");
    // }

    const formData = new FormData();
    formData.append("product_id", selectedProduct?.id);
    formData.append("ticket_count", buyAmount);
    callApi("cart/buyticket")
      .withBody(formData)
      .loadingGroup("cart/buyticket")
      .executeDispatch()
      .then((res) => {
        if (res?.status_code == 200) {
          closeConfirmTicketModal();
          navigate("/ticket-buy-success", { state: { data: res } });
        } else {
          setServerError(res?.message || "Unknown Server Error");
        }
      });
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      // Store the selected file in a ref
      setServerError("Please select Payment Image");
      return;
    }
    setPaymentImage(file);
  };

  const handleCardClick = (product) => {
    setSelectedProduct(product);
    setShowBuyTicketModal(true);
  };

  const openConfirmTicketModal = () => {
    if (AccessToken) {
      setShowConfirmTicketModal(true);
    } else {
      navigate("/signin");
    }
  };

  const closeConfirmTicketModal = () => {
    setShowConfirmTicketModal(false);
  };

  const openPaymentMethodModal = () => {
    setShowPaymentMethodModal(true);
  };

  const editTicketModal = () => {
    setShowConfirmTicketModal(false);
    setShowBuyTicketModal(true);
  };

  const editConfirmTicketModal = () => {
    setShowPaymentMethodModal(false);
    setShowConfirmTicketModal(true);
  };

  useEffect(() => {
    localStorage.setItem("currentLanguage", changeLanguage);
  }, [changeLanguage]);

  useEffect(() => {
    if (AccessToken) {
      callApi("history/luckydraw").executeDispatch();
    }
    if (activeTab == "allPrizes") {
      callApi("products/ticket")
        .withKeyParameter({
          page: page,
          perpage: ticketProduct?.per_page ? ticketProduct?.per_page : 20,
        })
        .executeDispatch();
    } else if (activeTab == "LuckyDrawLive") {
      callApi("luckydraw/liveList").executeDispatch();
    } else if (activeTab == "history") {
    } else if (activeTab == "myPrizes") {
      callApi("luckydraw/myPrices").executeDispatch();
    }
  }, [activeTab]);

  useEffect(() => {
    if (buytokenid) {
      if (ticketProduct?.data) {
        const findItem = ticketProduct?.data?.find(
          (item) => item?.id == buytokenid
        );
        handleCardClick(findItem);
      }
    }
  }, [buytokenid, ticketProduct]);

  useEffect(() => {
    dispatchStore(slice?.cart?.actions.clearSliceElement("paymentdetail"));
  }, []);

  const luckyNumberStyleMain = {
    display: "flex",
    justifyContent: "space-between",
  };

  const luckyNumberStyleChild = {
    width: "50%",
    flexWrap: "wrap",
  };

  useEffect(() => {
    // pushMyProductData();
  }, []);

  const handleClaimPrize = (clainID) => {
    if (clainID) {
      callApi("luckydraw/clainPrices")
        .withBody({ lucky_draw_winner_id: clainID })
        .executeDispatch()
        .then((res) => {
          setClaimSuccess("Prize claiming success");
        });
    }
  };

  const renderContent = () => {
    if (activeTab === "allPrizes") {
      return ticketProduct?.data?.map((item, key) => {
        return (
          <div
            key={key}
            className="flex flex-col flex-wrap mb-4 p-lr-16 p-tb-14 bg-foundation-grey-light-hover border-[1px] border-gray-300 rounded-lg"
          >
            <button
              onClick={() => handleCardClick(item)}
              // onClick={() => navigate(`/product-detail?id=${item?.slug}`)}
              className="flex h-[max-content]"
            >
              <div className="w-1/3 h-[max-content]">
                <img
                  src={
                    item?.feature_image ? item?.feature_image : LuckydrawItem1
                  }
                  alt="Slide 1"
                  className="w-full rounded-md"
                />
              </div>
              <div className="w-2/3 p-lr-16 p-tb-8 flex flex-col text-start relative">
                <span className="fs-14 font-semibold">{item?.name}</span>
                <span className="fs-11">
                  {getLanguageFile?.batch}
                  {item?.batch}
                </span>
                <span className="fs-11">{item?.label}</span>
                <span className="fs-11">{getLanguageFile.original_price}</span>
                <span className="fs-11 font-semibold">
                  MMK {item?.actual_price}
                </span>
                {/* <button onClick={() => navigate('/lucky-draw-live')} className="fs-11 text-left text-red-500 underline">View Live</button> */}
                {/* <div className="absolute top-0 right-0 flex items-center justify-between bg-red-500 text-white font-semibold rounded-full p-lr-8 m-l-4">
                  <span className="text-white fs-8 font-medium">
                    2 {getLanguageFile.day_left}
                  </span>
                </div> */}
              </div>
            </button>
            <div className="border-t border-gray-300 my-4"></div>
            <div className="flex justify-between items-center">
              <div className="flex flex-col gap-1 w-1/2">
                <span className="text-gray-600 fs-11">
                  {getLanguageFile.remaining_tickets} :{" "}
                  <span className="text-customGray">
                    {item?.remaining_tickets}
                  </span>{" "}
                  / {item?.ticket}
                </span>
                <div className="w-full bg-gray-300 h-3 rounded-full">
                  <div
                    className="bg-yellow-400 h-3 rounded-full"
                    style={{
                      width: `${
                        (item?.remaining_tickets / item?.ticket) * 100 + "%"
                      }`,
                    }}
                  ></div>
                </div>
              </div>
              <button
                onClick={() => handleCardClick(item)}
                className="w-[128px] h-[32px] flex items-center p-lr-16 bg-yellow-400 rounded-full transition-none"
              >
                <span className="flex items-center justify-center p-1 bg-gold-500 rounded-full mr-2">
                  <StarIcon
                    id="StarIcon"
                    className="w-[12px] h-[12px] text-white"
                  />
                </span>
                <span className="fs-12 font-semibold">
                  {item?.cost_token_point}{" "}
                  {item?.cost_token_point > 1
                    ? getLanguageFile.tokens
                    : getLanguageFile.token}
                </span>
              </button>
            </div>
          </div>
        );
      });
    } else if (activeTab == "LuckyDrawLive") {
      return LuckyDrawLiveProduct?.map((item, key) => {
        return (
          <div
            key={key}
            className="flex flex-col flex-wrap mb-4 p-lr-16 p-tb-14 bg-gold-500 border-[1px] border-gray-300 rounded-lg"
          >
            <div className="flex">
              <div className="w-1/3">
                <img
                  src={
                    item?.product?.feature_image
                      ? item?.product?.feature_image
                      : LuckydrawItem1
                  }
                  alt="Slide 1"
                  className="w-full rounded-md"
                />
              </div>
              <div className="w-2/3 p-lr-16 p-tb-8 flex flex-col relative">
                <span className="fs-14 font-semibold">
                  {item?.product?.name}
                </span>
                <span className="fs-11 font-semibold">
                  {getLanguageFile?.batch}
                  {item?.batch}
                </span>
                <span className="fs-11">{item?.product?.label}</span>

                {/* {item?.product?.off_price == 0 && (
                  <span className="fs-11">
                    {getLanguageFile.original_price}
                  </span>
                )} */}
                <span className="fs-11 font-semibold">
                  MMK {item?.product?.actual_price}
                </span>
                {item?.opened_at && (
                  <span className="fs-11 text-300">
                    {getLanguageFile.open_at} {item?.opened_at}
                  </span>
                )}
              </div>
            </div>
            <div className="border-t border-gray-300 my-4"></div>
            {/* <div className="flex justify-between items-center mb-4">
              <div className="flex flex-col gap-1">
                <p className="fs-11 text-300">Lucky number</p>
                <span className="bg-gold-500 text-gold-900 fs-12 font-semibold rounded-full w-[25px] h-[25px] text-center p-1">
                  11
                </span>
              </div>
              <button
                onClick={() => navigate("/lucky-draw-detail")}
                className="bg-white text-500 border-[1px] border-black font-semibold rounded-full px-4 py-2 fs-12 transition-none"
              >
                View Winner
              </button>
            </div> */}
            <button
              onClick={() =>
                navigate("/lucky-draw-detail", {
                  state: {
                    data: item?.id,
                    ticket_id: item?.ticket_id,
                    backto: "LuckyDrawLive",
                  },
                })
              }
              className="bg-white text-500 border-[1px] border-black font-semibold rounded-full px-4 py-2 mb-4 fs-12 transition-none"
            >
              Watch Live
            </button>
            {item?.ticket_numbers?.length > 0 && (
              <div className="grid grid-cols-1 items-center bg-white p-2 rounded-md">
                <p className="fs-12 text-300">
                  {getLanguageFile.my_ticket_number}
                </p>
              </div>
            )}

            <div className="flex flex-wrap mt-3">
              {item?.ticket_numbers?.map((number) => (
                <span
                  key={number}
                  className="bg-foundation-grey-normal-hover text-white border-[1px] fs-12 font-semibold rounded-full min-w-[25px] min-h-[25px] text-center p-1 mr-1"
                >
                  {number}
                </span>
              ))}
            </div>
          </div>
        );
      });
    } else if (activeTab === "myPrizes") {
      return myPrizesProduct?.map((item, key) => {
        // start in here
        return (
          <div
            key={key}
            className="flex flex-col flex-wrap mb-4 p-lr-16 p-tb-14 bg-gold-500 border-[1px] border-gray-300 rounded-lg"
            onClick={() => null}
            // onClick={() => navigate("/lucky-draw-detail", { state: { data: item?.id, ticket_id: item?.ticket_id } })}
          >
            <div className="flex">
              <div className="w-1/3">
                <img
                  src={
                    item?.product?.feature_image
                      ? item?.product?.feature_image
                      : LuckydrawItem1
                  }
                  alt="Slide 1"
                  className="w-full rounded-md"
                />
              </div>
              <div className="w-2/3 p-lr-16 p-tb-8 flex flex-col relative">
                <span className="fs-14 font-semibold">
                  {item?.product?.name}
                </span>
                <span className="fs-11 font-semibold">
                  {getLanguageFile?.batch}
                  {item?.batch}
                </span>
                <span className="fs-11 font-semibold">
                  MMK {item?.product?.actual_price}
                </span>
                {item?.opened_at && (
                  <span className="fs-11 text-300">
                    {getLanguageFile.open_at} {item?.opened_at}
                  </span>
                )}
                <div className="absolute top-0 right-0 flex items-center justify-between bg-red-500 text-white font-semibold rounded-full p-lr-8 m-l-4">
                  <span className="text-white fs-8 font-medium">
                    {getLanguageFile.you_win}!
                  </span>
                </div>
              </div>
            </div>
            <div className="border-t border-gray-300 my-4"></div>
            <div className="flex justify-between items-center mb-4">
              <div className="flex flex-col gap-1">
                <p className="fs-11 text-300">{getLanguageFile.lucky_nunber}</p>
                <span className="bg-white text-gold-900 fs-12 font-semibold rounded-full w-[25px] h-[25px] text-center p-1">
                  {item?.lucky_number}
                </span>
              </div>
              <button
                className="bg-white text-500 font-semibold rounded-full px-4 py-2 fs-12 transition-none"
                onClick={() => handleClaimPrize(item?.id)}
              >
                {getLanguageFile.claim_prize}!
              </button>
            </div>
            <div
              className="flex justify-between items-center bg-white p-2 rounded-md"
              style={luckyNumberStyleMain}
            >
              <p className="fs-12 text-300">
                {getLanguageFile.my_ticket_number}
              </p>
            </div>
            <div className="flex mt-3" style={luckyNumberStyleChild}>
              {item?.ticket_numbers?.map((number) => (
                <span
                  key={number}
                  className="bg-foundation-grey-normal-hover text-white border-[1px] fs-12 font-semibold rounded-full min-w-[25px] min-h-[25px] text-center p-1 mr-1"
                >
                  {number}
                </span>
              ))}
            </div>
          </div>
        );
      });
    } else if (activeTab === "history") {
      return historyticketProduct?.map((item, key) => {
        return (
          <div
            key={key}
            className="flex flex-col flex-wrap mb-4 p-lr-16 p-tb-14 bg-foundation-grey-light-hover border-[1px] border-gray-300 rounded-lg"
            onClick={() =>
              navigate("/lucky-draw-detail", {
                state: {
                  data: item?.id,
                  ticket_id: item?.ticket_id,
                  backto: "history",
                },
              })
            }
          >
            <div className="flex">
              <div className="w-1/3">
                <img
                  src={
                    item?.product?.feature_image
                      ? item?.product?.feature_image
                      : LuckydrawItem1
                  }
                  alt="Slide 1"
                  className="w-full rounded-md"
                />
              </div>
              <div className="w-2/3 p-lr-16 p-tb-8 flex flex-col relative">
                <span className="fs-14 font-semibold">
                  {item?.product?.name}
                </span>
                <span className="fs-11 font-semibold">
                  {getLanguageFile?.batch}
                  {item?.batch}
                </span>
                <span className="fs-11">{item?.product?.label}</span>

                {/* {item?.product?.off_price == 0 && (
                  <span className="fs-11">
                    {getLanguageFile.original_price}
                  </span>
                )} */}
                <span className="fs-11 font-semibold">
                  MMK {item?.product?.actual_price}
                </span>
                {item?.opened_at && (
                  <span className="fs-11 text-300">
                    {getLanguageFile.open_at} {item?.opened_at}
                  </span>
                )}
              </div>
            </div>
            <div className="border-t border-gray-300 my-4"></div>
            {/* <div className="flex justify-between items-center mb-4">
              <div className="flex flex-col gap-1">
                <p className="fs-11 text-300">Lucky number</p>
                <span className="bg-gold-500 text-gold-900 fs-12 font-semibold rounded-full w-[25px] h-[25px] text-center p-1">
                  11
                </span>
              </div>
              <button
                onClick={() => navigate("/lucky-draw-detail")}
                className="bg-white text-500 border-[1px] border-black font-semibold rounded-full px-4 py-2 fs-12 transition-none"
              >
                View Winner
              </button>
            </div> */}
            <div className="grid grid-cols-1 items-center bg-white p-2 rounded-md">
              <p className="fs-12 text-300">
                {getLanguageFile.my_ticket_number}
              </p>
            </div>
            <div className="flex flex-wrap mt-3">
              {item?.ticket_numbers.map((number) => (
                <span
                  key={number}
                  className="bg-foundation-grey-normal-hover text-white border-[1px] fs-12 font-semibold rounded-full min-w-[25px] min-h-[25px] text-center p-1 mr-1"
                >
                  {number}
                </span>
              ))}
            </div>
          </div>
        );
      });
    }
  };

  return (
    <>
      {serverError && (
        <AlertPopup
          type={"error"}
          btnLabel={getLanguageFile.propbox_ok}
          text={serverError}
          onClick={() => setServerError(null)}
        />
      )}

      {tokenNotEnoughError && (
        <AlertPopup
          type={"warning"}
          btnLabel={getLanguageFile.propbox_buy_token}
          text={tokenNotEnoughError}
          onClick={() => navigate("/token")}
          cancel={getLanguageFile.propbox_ok}
          close={() => setTokenNotEnoughError(null)}
        />
      )}

      {claimSuccess && (
        <AlertPopup
          type={"success"}
          btnLabel={"Go to my orders"}
          text={claimSuccess}
          onClick={() => navigate("/my-orders-to-ship")}
          cancel={"Ok"}
          close={() => setClaimSuccess(null)}
        />
      )}
      <Header
        changeLanguageClick={changeLanguageData}
        getLanguageFile={getLanguageFile}
      />

      <div id="body" className="bottombar" style={{ overflow: "hidden auto" }}>
        <div className="container p-lr-16">
          {/* Lucky Draw Header */}
          <section className="flex flex-col gap-2 mb-4">
            <div className="flex items-center">
              <h1 className="fs-14 font-bold">{getLanguageFile.lucky_draws}</h1>
            </div>
            <p className="text-500 fs-12">
              {getLanguageFile.try_ticket_buy_using_token}
            </p>
          </section>

          {/* Tab Navigation */}
          <section className="flex gap-2 mb-4">
            {/* <button
              className={`text-500 fs-12 font-semibold px-2 py-2 border-[1px] flex items-center ${
                activeTab === "allPrizes"
                  ? "border-foundation-grey-normal-active"
                  : "border-Light/active"
              } rounded-full`}
              onClick={() => setActiveTab("allPrizes")}
            >
              {getLanguageFile.all_prizes}
            </button> */}

            {AccessToken && (
              <button
                className={`text-500 fs-12 font-semibold px-2 py-2 border-[1px] flex items-center ${
                  activeTab === "history"
                    ? "border-foundation-grey-normal-active"
                    : "border-Light/active"
                } rounded-full`}
                onClick={() => setActiveTab("history")}
              >
                {getLanguageFile.history}
              </button>
            )}

            {AccessToken && (
              <button
                className={`text-500 fs-12 font-semibold px-2 py-2 border-[1px] flex items-center ${
                  activeTab === "LuckyDrawLive"
                    ? "border-foundation-grey-normal-active"
                    : "border-Light/active"
                } rounded-full`}
                onClick={() => setActiveTab("LuckyDrawLive")}
              >
                LuckyDraw Live
              </button>
            )}

            {AccessToken && (
              <button
                className={`text-500 fs-12 font-semibold px-2 py-2 border-[1px] flex items-center ${
                  activeTab === "myPrizes"
                    ? "border-foundation-grey-normal-active"
                    : "border-Light/active"
                } rounded-full relative`}
                onClick={() => setActiveTab("myPrizes")}
              >
                {getLanguageFile.my_prizes}
                {myPrizesProduct?.length > 0 && (
                  <span className="ml-1 bg-red-500 text-white rounded-full px-1 fs-8 font-bold">
                    {myPrizesProduct?.length}
                  </span>
                )}
              </button>
            )}
          </section>

          {/* Render Content Based on Active Tab */}
          {renderContent()}
        </div>
      </div>

      <BottomNav active={3} getLanguageFile={getLanguageFile} />

      {/* Modals */}
      <BuyTicketModal
        show={showBuyTicketModal}
        onClose={() => setShowBuyTicketModal(false)}
        product={selectedProduct}
        onConfirm={openConfirmTicketModal}
        buyAmount={buyAmount}
        setBuyAmount={setBuyAmount}
        setTokenNotEnoughError={setTokenNotEnoughError}
      />

      <ConfirmTicketModal
        show={showConfirmTicketModal}
        handleEdit={editTicketModal}
        onClose={closeConfirmTicketModal}
        ticketCount={buyAmount}
        onConfirm={() => {
          // openPaymentMethodModal();
          handleBuy();
        }}
        tokenCount={selectedProduct?.cost_token_point * buyAmount}
      />

      <PaymentMethodModal
        show={showPaymentMethodModal}
        onClose={() => setShowPaymentMethodModal(false)}
        handleEdit={editConfirmTicketModal}
        selectedPaymentOption={selectedPaymentOption}
        setSelectedPaymentOption={setSelectedPaymentOption}
        useCardNextTime={useCardNextTime}
        setUseCardNextTime={setUseCardNextTime}
        handleBuy={handleBuy}
        handleFileChange={handleFileChange}
        fileInputRef={fileInputRef}
        paymentImage={paymentImage}
        loadingGroup="cart/buyticket"
      />
    </>
  );
}
